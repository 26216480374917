
/**
 * Utility functions for making fetch calls. An instance of this class is available as window.Grape.fetches
 */
class Fetches {
	constructor()
	{
		this.standardOptions = {
			cache: 'no-cache'
		};
	}

	/**
	 * Performs an HTTP request and returns a promise for the response.
	 *
	 * @param {string} url - The URL to send the HTTP request to.
	 * @param {Object} [options={}] - Additional options to customize the request.
	 * @param {Object} [options.headers] - Optional headers for the request.
	 * @param {string} [options.method="GET"] - HTTP method (e.g., GET, POST).
	 * @param {Object} [options.body] - Optional request body for POST or PUT methods.
	 *
	 * @returns {Promise<Response>} A promise that resolves to the HTTP response.
	 */
	async fetch(url, options={}) {
		let response = await fetch (url, Object.assign({}, this.standardOptions, options));
		return response;
	}

	/**
	 * Performs an HTTP request and returns a promise that resolves to a Blob.
	 *
	 * @param {string} url - The URL to send the HTTP request to.
	 * @param {Object} [options={}] - Additional options to customize the request.
	 *
	 * @returns {Promise<Blob>} A promise that resolves to the response as a Blob.
	 */
	async fetchBlob(url, options={}) {
		let response = await fetch (url, Object.assign({}, this.standardOptions, options));
		return await response.blob();
	}

	/**
	 * Performs an HTTP request and returns a promise that resolves to a JSON object.
	 *
	 * @param {string} url - The URL to send the HTTP request to.
	 * @param {Object} [options={}] - Additional options to customize the request.
	 *
	 * @returns {Promise<Object>} A promise that resolves to the JSON-parsed response.
	 */
	async fetchJSON(url, options={}) {
		let headers = new Headers(options.headers || {});
		headers.set('Accept', 'application/json');
		options.headers = headers;
		let response = await fetch (url, Object.assign({}, this.standardOptions, options));
		return await response.json();
	}

	/**
	 * Performs a GET request with query parameters and returns a promise that resolves to a JSON object.
	 *
	 * @param {string} url - The URL to send the HTTP request to.
	 * @param {Object} [params={}] - An object representing query parameters to append to the URL.
	 * @param {Object} [options={}] - Additional options to customize the request.
	 *
	 * @returns {Promise<Object>} A promise that resolves to the JSON-parsed response.
	 */
	async getJSON(url, params={}, options={}) {
		let _params = this.params2String(params);
		let _url = new URL(url, window.location);
		if (_params.toString().length)
			_url.search = _params;
		return await this.fetchJSON(_url, options);
	}

	/**
	 * Performs a POST request with JSON data and returns a promise that resolves to a JSON object.
	 *
	 * @param {string} url - The URL to send the HTTP request to.
	 * @param {Object|string} data - The data to be sent in the body of the request.
	 * @param {Object} [options={}] - Additional options to customize the request.
	 *
	 * @returns {Promise<Object>} A promise that resolves to the JSON-parsed response.
	 */
	async postJSON(url, data, options={}) {
		let headers = new Headers(options.headers || {});
		headers.set('Content-type', 'application/json');
		options.headers = headers;
		options.method = 'POST';
		if (typeof data == 'string')
			options.body = data;
		else
			options.body = JSON.stringify(data);
		return await this.fetchJSON(url, options);
	}

	/**
	 * Performs a DELETE request with optional query parameters and returns a promise that resolves to a JSON object.
	 *
	 * @param {string} url - The URL to send the HTTP request to.
	 * @param {Object} [params={}] - An object representing query parameters to append to the URL.
	 * @param {Object} [options={}] - Additional options to customize the request.
	 *
	 * @returns {Promise<Object>} A promise that resolves to the JSON-parsed response.
	 */
	async deleteJSON(url, params={}, options={}) {
		let _params = this.params2String(params);
		let _url = new URL(url, window.location);
		if (_params.toString().length)
			_url.search = _params;
		options.method = 'DELETE';
		return await this.fetchJSON(_url, options);
	}

	/**
	 * Performs a PUT request with JSON data and returns a promise that resolves to a JSON object.
	 *
	 * @param {string} url - The URL to send the HTTP request to.
	 * @param {Object|string} data - The data to be sent in the body of the request.
	 * @param {Object} [options={}] - Additional options to customize the request.
	 *
	 * @returns {Promise<Object>} A promise that resolves to the JSON-parsed response.
	 */
	async putJSON(url, data, options={}) {
		let headers = new Headers(options.headers || {});
		headers.set('Content-type', 'application/json');
		options.headers = headers;
		options.method = 'PUT';
		if (typeof data == 'string')
			options.body = data;
		else
			options.body = JSON.stringify(data);
		return await this.fetchJSON(url, options);
	}

	/**
	 * Performs a POST request with arbitrary data and returns a promise for the response.
	 * 
	 * @param {string} url - The URL to send the HTTP request to.
	 * @param {any} data - The data to be sent in the body of the request.
	 * @param {Object} [options={}] - Additional options to customize the request.
	 * 
	 * @returns {Promise<Response>} A promise that resolves to the HTTP response.
	 */
	async post(url, data, options={}) {
		let headers = new Headers(options.headers || {});
		options.headers = headers;
		options.method = 'POST';
		options.body = data;
		return await this.fetch(url, options);
	}

	/**
	 * Converts an object of parameters into a URL-encoded query string.
	 *
	 * @param {Object} params - The object containing key-value pairs to be serialized.
	 *
	 * @returns {string} A URL-encoded query string.
	 */
	params2String(params)
	{
		let s = [];
		const add = (k, v) => {
			v = typeof v === 'function' ? v() : v;
			v = v === null ? '' : v === undefined ? '' : v;
			s[s.length] = encodeURIComponent(k) + '=' + encodeURIComponent(v);
		};

		const buildParams = (prefix, obj) => {
			let i, len, key;
			if (prefix)
			{
				if (Array.isArray(obj))
					for (i = 0, len = obj.length; i < len; i++)
						buildParams(
							prefix + '[' + (typeof obj[i] === 'object' && obj[i] ? i : '') + ']',
							obj[i]
						);
				else if (obj && (!(obj.toString instanceof Function) || obj.toString === Object.prototype.toString))
					for (key in obj)
						buildParams(`${prefix}[${key}]`, obj[key]);
				else
					add(prefix, obj);
			}
			else if (Array.isArray(obj))
			{
				for (i = 0, len = obj.length; i < len; i++)
					add(obj[i].name, obj[i].value);
			}
			else
			{
				for (key in obj)
					buildParams(key, obj[key]);
			}
			return s;
		};
		return buildParams('', params).join('&');
	}

	/**
	 * Builds a full URL by combining a base URL, a relative URL, and query parameters.
	 *
	 * @param {string} url - The relative or absolute URL to be resolved against the base URL.
	 * @param {Object} [params={}] - An object representing query parameters to append to the URL.
	 * @param {Location|string} [base=window.location] - The base URL or location against which the URL is resolved.
	 *
	 * @returns {URL} The constructed URL object with the applied query parameters.
	 */
	buildURL(url, params={}, base=window.location) {
		let _url = new URL(url, base);
		let paramstring = this.params2String(params);
		_url.search = paramstring;
		return _url;
	}
}

export default Fetches;
