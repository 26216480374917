/**
 * Grape route definition
 * @typedef {Object} GrapeRouteDefinition
 * @property {string} template - Template data
 * @property {string} page_class - Name of class to instantiate when this route is triggered
 * @property {string} file - Path to file to fetch
 * @property {string} container - ID of element to load page into
 */

/**
 * Register new route at uri with options
 * @memberof GrapeApp
 */
GrapeApp.prototype.route = function (uri, _options) {
	const default_options = {
		container: 'container', // Default id of target element
		page_class: false, // JavaScript Object
		setup: null,
		teardown: null,
		meta: null,
		beforeRouteEnter: null,
		beforeRouteUpdate: null,
		beforeRouteLeave: null
	};

	let options = Object.assign({}, default_options, _options);

	// Legacy: Change pageClass to page_class
	if (options.pageClass && !options.page_class)
	{
		// TODO: Log as deprecated
		options.page_class = options.pageClass;
		delete options.pageClass;
	}

	// Set current directory name if not provided
	if (options.page_class && typeof __DIRNAME__ !== 'undefined')
		options.page_class.__DIRNAME__ = __DIRNAME__;

	// Create page instance
	let page_def = Grape.pages.add_page({
		page_id: uri,
		_class: options.page_class ?? null,
		template: options.template ?? null,
		template_filename: options.template_filename ?? null
	});

	Grape.router.add_route(uri, {
		setup: options.setup ?? null,
		load: options.load ?? null,
		unload: options.unload ?? null,
		teardown: options.teardown ?? null,
		beforeRouteEnter: options.beforeRouteEnter ?? null,
		beforeRouteUpdate: options.beforeRouteUpdate ?? null,
		beforeRouteLeave: options.beforeRouteLeave ?? null,

		page: page_def,
		meta: options.meta ?? null,
		container_id: options.container || 'container',
		plugin_options: options.plugin_options ?? null
	});
};
