
import ko from 'knockout';

/**
 * @class GrapeToasts
 * @description An instance of this class is usually available as window.Grape.toast
 */
export default class GrapeToasts {
	constructor(Grape) {
		this.Grape = Grape;
		this.toasts = {};
		this.providers = {};
	}

	registerToasts(toasts){
		for (let toast of toasts){
			this.register(toast);
		}
	}

	register(toast){
		this.toasts[toast.name] = {
			toast_class: toast.toast_class,
			template: toast.template,
			template_filename: toast.template_filename || null,
			provider: toast.provider || null,
			options: toast.options || {}
		};
	}

	/**
	 * Promisified version of show(name, bindings, display_options)
	 * @param {Object} options
	 * @param {Object} options.bootstrap_options - Extra options passed to bootstrap modal function
	 * @memberof GrapeToasts
	 * @return {Promise<any>} Returns a promise of the toast result after closing.  The toast result can be passed to the toast.close() function, or returned in the instance's onClose() function, or by changing the instance's toast_result value
	 */
	open(name, bindings, options) {
		return new Promise((resolve, reject) => {
			let jqel = this.show(name, bindings, Object.assign({
				onClose: function (d) { resolve(d); }
			}, options));
		});
	}

	/**
	 * Create and show a toast
	 * @param {text} name - Toast name, must be a toast that has been previously registered,
	 * @param {object} bindings - Data to pass on to the Toast class.
	 * @param {object} options - Options
	 * @param {function} options.onClose - Callback when the toast is closed.
	 * @memberof GrapeToasts
	 */
	async show(name, bindings, options = {}) {
		if (this.toasts[name])
		{
			// Toast definition
			let toast = this.toasts[name];
			let provider = null;

			if (toast.provider && this.providers.hasOwnProperty(toast.provider))
			{
				provider = this.providers[toast.provider];
			}
			else
			{
				if (!Object.entries(this.providers).length)
				{
					console.error('GrapeUI: No toast providers installed');
					return null;
				}
				else
				{
					console.warn('Provider "%s" not found. Using first available', toast.provider);
					provider = Object.entries(this.providers)[0][1];
				}
			}

			let display_options = Object.assign({}, options);
			if (toast.hasOwnProperty('options'))
				display_options = Object.assign(toast.options, display_options);

			// console.debug('Loading HTML into DOM for toast', this.toasts[name]);

			let element = document.createElement('div');
			element.id = [
				'tmptoast',
				name,
				Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)
			].join('_');

			if (toast.file)
			{
				let data = await fetch(toast.file);
				element.innerHTML = data;
			}
			else if (
				window.translated_pages &&
				window.currentLanguageCode &&
				toast.template_filename &&
				window.translated_pages[toast.template_filename] &&
				window.translated_pages[toast.template_filename][window.currentLanguageCode]
			)
			{
				element.innerHTML = window.translated_pages[toast.template_filename][window.currentLanguageCode];
			}
			else if (toast.template)
			{
				element.innerHTML = toast.template;
			}
			// console.debug('toast element innerHTML:', element.innerHTML);

			await provider.show({ element, toast }, bindings, display_options);
			window.Grape.emit('toast_shown', name, toast, element);
			return element;
		}
		else
		{
			console.error('No toast named ' + name + ' could be found! ');
			return null;
		}
	}
}
