import * as RouteUtils from './RouteUtils.js';

/**
 * @typedef {Object} RouteDefinitionOptions
 * @param {string} name
 * @param {string} container
 * @param {function} page_class
 * @param {string} template
 * @param {*} file
 * @param {*} page
 * @param {function} setup
 * @param {function} teardown
 * @param {function} load
 * @param {function} unload
 */
/**
 * @class RouteDefinition
 */
export default class RouteDefinition {
	/**
	 * @constructor
	 * @param {RoutePart} node The path being registered
	 * @param {RouteDefinitionOptions} options Options for the path. If not provided the node is not considered a valid navigation target
	 */
	constructor(node, options) {
		this.is_defined = false;
		this.child_routes = {};
		this.binding_route = null;

		this.path = node?.path?.toLowerCase();
		this.node_type = node?.node_type ?? 'literal';
		this.route_depth = node?.route_depth ?? 0;
		this.parent_depth = node?.parent_depth ?? 0;
		this.hash = node?.hash ?? this.path;

		this.handlers = {};

		if (this.node_type === 'variable') this.route_part = `:${this.path}`;
		else this.route_part = this.path;

		if (options)
			this.define(options);
	}

	/**
	 * Configure the route with proper options
	 * @memberof RouteDefinition
	 * @param {RouteDefinitionOptions} options
	 */
	define(options) {
		if (this.is_defined)
		{
			RouteUtils.log_warn('Route is already defined. Skipping ', this.hash);
			return null;
		}

		this.is_defined = true;

		this.name = options?.name;
		this.container = options?.container_id;
		this.page_class = options?.page_class;
		this.template = options?.template;
		this.file = options?.file;
		this.page = options?.page;
		this.meta = options?.meta;
		this.plugin_options = options?.plugin_options;

		this.handlers.setup = options?.setup;
		this.handlers.load = options?.load;
		this.handlers.unload = options?.unload;
		this.handlers.teardown = options?.teardown;

		this.handlers.beforeRouteEnter = options?.beforeRouteEnter;
		this.handlers.beforeRouteUpdate = options?.beforeRouteUpdate;
		this.handlers.beforeRouteLeave = options?.beforeRouteLeave;

		return this;
	}

	add_children(node_list, options) {
		const current = node_list[this.route_depth + 1];
		if (!current)
		{
			RouteUtils.log_warn('Unable to add child route on', this.path);
			return null;
		}

		const is_leaf = node_list.length === current.route_depth + 1;
		const search_path = current?.path?.toLowerCase();

		// Find existing instance of node
		let target = null;
		if (current.node_type === 'literal')
		{
			target = this.child_routes[search_path] ?? new RouteDefinition(current);
			this.child_routes[search_path] ??= target;
		}
		else if (current.node_type === 'variable')
		{
			target = this.binding_route ?? new RouteDefinition(current);
			this.binding_route ??= target;
		}
		else
		{
			RouteUtils.log_warn(`Registration failed on ${this.hash}: Node ${search_path} has an invalid node_type ${current.node_type}`);
			return null;
		}

		// Check for binding registration clashes. Eg. /sale/:sale_id/id should block /sale/:client_id/id
		if (target.path !== search_path)
		{
			RouteUtils.log_warn(`Registration failed on ${this.hash}: Variable node ${search_path} is already registerd as path :${target.path}`);
			return null;
		}
		else if (is_leaf)
		{
			return target.define(options) ? target : null;
		}
		else
		{
			return target.add_children(node_list, options);
		}
	}

	/**
	 * Calls beforeRouteEnter on the route
	 * @memberof RouteDefinition
	 * @param {RouteMatch} to - object with all information about the matched route
	 * @returns {boolean|string} Returns a bool indicating if navigation should be cancelled, or a string to redirect to instead
	 */
	async beforeRouteEnter(to, from) {
		if (typeof (this.handlers.beforeRouteEnter) === 'function')
		{
			RouteUtils.log_debug('BEFORE ROUTE ENTER:', to?.clean_hash);
			return (await this.handlers.beforeRouteEnter(to, from)) ?? true;
		}
		return true;
	}

	/**
	 * Calls beforeRouteUpdate on the route
	 * @memberof RouteDefinition
	 * @param {string} to - object with all information about the matched route
	 * @param {string} from - object with all information about the matched route
	 * @returns {boolean|string} Returns a bool indicating if navigation should be cancelled, or a string to redirect to instead
	 */
	async beforeRouteUpdate(to, from) {
		if (typeof (this.handlers.beforeRouteUpdate) === 'function')
		{
			RouteUtils.log_debug('BEFORE ROUTE UPDATE:', from?.clean_hash);
			return (await this.handlers.beforeRouteUpdate(to, from)) ?? true;
		}
		return true;
	}

	/**
	 * Calls beforeRouteLeave on the route
	 * @memberof RouteDefinition
	 * @param {string} to - object with all information about the matched route
	 * @param {string} from - object with all information about the matched route
	 * @returns {boolean|string} Returns a bool indicating if navigation should be cancelled, or a string to redirect to instead
	 */
	async beforeRouteLeave(to, from) {
		if (typeof (this.handlers.beforeRouteLeave) === 'function')
		{
			RouteUtils.log_debug('BEFORE ROUTE LEAVE:', from?.clean_hash);
			return (await this.handlers.beforeRouteLeave(to, from)) ?? true;
		}
		return true;
	}

	/**
	 * Calls setup on the route
	 * @memberof RouteDefinition
	 * @param {RouteMatch} route - object with all information about the matched route
	 */
	async setup(route, bindings, params) {
		window.Grape.emit('route_triggered', route.route_hash);
		RouteUtils.log_debug('SETUP:', route.route_hash, bindings);
		// TODO if Route.allowed_roles is set and Current User Roles is not in Route.allowed_roles and user not logged in, redirect to login page
		if (typeof (this.handlers.setup) === 'function')
		{
			await this.handlers.setup(route, bindings, params, this?.meta);
		}
		else await RouteUtils.default_page_setup(route, bindings, params, this?.meta);
	}

	/**
	 * Calls load on the route
	 * @memberof RouteDefinition
	 * @param {RouteMatch} route - object with all information about the matched route
	 */
	async load(route, bindings, params) {
		RouteUtils.log_debug('LOAD:', route.route_hash);
		if (typeof (this.handlers.load) === 'function')
		{
			await this.handlers.load(route, bindings, params, this?.meta);
		}
		else await RouteUtils.default_page_load(route, bindings, params, this?.meta);
	}

	/**
	 * Calls unload on the route
	 * @memberof RouteDefinition
	 * @param {RouteMatch} RouteMatch object with all information about the matched route
	 */
	async unload(route, bindings, params) {
		RouteUtils.log_debug('UNLOAD:', route.route_hash);
		if (typeof (this.handlers.unload) === 'function')
		{
			await this.handlers.unload(route, bindings, params, this?.meta);
		}
	}

	/**
	 * Calls teardown on the route
	 * @memberof RouteDefinition
	 * @param {RouteMatch} RouteMatch object with all information about the matched route
	 */
	async teardown(route, bindings, params) {
		RouteUtils.log_debug('TEARDOWN:', route.route_hash);
		if (typeof (this.handlers.teardown) === 'function')
		{
			await this.handlers.teardown(route, bindings, params, this?.meta);
		}
		else await RouteUtils.default_page_teardown(route, bindings, params, this?.meta);
	}
}
