
const debug = console.debug;

/**
 * @class GrapePlugins
 */
export default class GrapePlugins {

	constructor(Grape) {
		this.Grape = Grape;
		this.plugins = [];

		this.logger = Grape.logger;
		this._app_lifecycle_hooks = ['onInit', 'onLoad', 'afterInit'];
		this._app_lifecycle_hooks_triggered = {};
	}

	/**
	 * Registers an array of plugins by calling the `register` method for each plugin.
	 * @memberof GrapePlugins
	 * @param {Array} plugins - An array of plugins to be registered.
	 */
	registerPlugins(plugins){
		for (let plugin of plugins){
			this.register(plugin);
		};
	}

	/**
	 * Registers a plugin
	 * @name registerPlugin
	 * @param {GrapePlugin} object -
	 * @memberof GrapePlugins
	 */
	async register(plugin_class, options = {}) {
		try
		{
			const instance = new plugin_class(this.Grape, options);
			this.plugins.push({ plugin_class: plugin_class, instance: instance });
			this.Grape.logger.info('Plugin loaded [' + (instance.name || '') + '] from [' + (plugin_class.name|| '') + ']', instance);

			/* Trigger any app lifecycle hooks */
			for (let [hook_name, params] of Object.entries(this._app_lifecycle_hooks_triggered))
			{
				if (instance[hook_name] && typeof instance[hook_name] == 'function')
				{
					await this._triggerPluginHooks (hook_name, params, [[instance[hook_name], instance, params]]);
				}
			}
		} catch (e) {
			this.Grape.logger.error('Error while loading plugin:' + e.message, e);
		}
	}


	/**
	 * Returns a promise that will resolve after all plugin hooks has been resolved. The plugin hooks are created sequentially
	 * @memberof GrapePlugins
	 */
	triggerPluginHooks(hook_name, params) {
		debug('triggerPluginHooks(' + hook_name + ', ...)');

		if (this._app_lifecycle_hooks.indexOf(hook_name) > -1)
			this._app_lifecycle_hooks_triggered[hook_name] = params;

		const promise_creators = [];

		for (let plugin of this.plugins)
		{
			if (plugin.instance[hook_name] && typeof plugin.instance[hook_name] == 'function')
			{
				const func = plugin.instance[hook_name];
				promise_creators.push([func, plugin.instance, params]);
			}
		}
		
		if (promise_creators.length == 0)
		{
			debug('triggerPluginHooks(' + hook_name + ') has zero hooks');
			return;
		}

		return this._triggerPluginHooks (hook_name, params, promise_creators);
	}

	/**
	 * Internal plugin hooks function
	 * @private
	 */
	_triggerPluginHooks(hook_name, params, promise_creators) {

		return new Promise((resolve, reject) => {
			function trigger(list, results = []) {
				const _next = (res) => {
					results.push(res);
					if (list.length > 0)
						trigger(list, results);
					else
					{
						debug('triggerPluginHooks(' + hook_name + ') is done. Results: [', results, ']');
						resolve(results);
					}
				};

				let l = list.shift();
				let p = null;
				try
				{
					p = l[0].apply(l[1], l[2]);
				} catch (err) {
					console.error('Error in plugin hook:', err);
					return _next({ status: 'rejected', reason: err });
				}

				if (p && typeof p.then == 'function')
				{
					p.then((res) => {
						_next({ status: 'fulfilled', value: res });
					}).catch((err) => {
						console.error('Error in plugin hook:', err);
						_next({ status: 'rejected', reason: err });
					});
				}
				else // hook did not return a promise
				{
					_next({ status: 'fulfilled', value: p });
				}
			}

			trigger(promise_creators);
		});
	}

}
